import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "d664fc85-7f4b-4bda-a626-2c92d8cd51cd"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesD664fc857f4b4bdaA6262c92d8cd51cd(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesD664fc857f4b4bdaA6262c92d8cd51cd`}>
      {props.children}
    </div>
  );
}

// id: "e099f4ef-cca1-4127-b324-2bbdea6bf72a"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultE099f4efCca14127B3242bbdea6bf72a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultE099f4efCca14127B3242bbdea6bf72a page`}>
      {props["header"] || <MembersAreaHeader098c375223f54620863607756ee99fce parentTag="HeaderB5bf378c4b944a9881c15208cd8f847c" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterC1c488e0A0434df0AcacA21d8b342e1f" {...props} />}
    </div>
  );
}

// id: "ad39381d-ebab-4c89-b2db-de2f6a99578e"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoAd39381dEbab4c89B2dbDe2f6a99578e(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogoAd39381dEbab4c89B2dbDe2f6a99578e logo`} alt="logo" />
  );
}

// id: "0ffcd124-5106-448e-aa55-9c33b33d7e38"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeader0ffcd1245106448eAa559c33b33d7e38(props: any) {
  return (
    <ShortHeaderCba15831802f460d999cC5406c94c755 parentTag="DefaultHeader0ffcd1245106448eAa559c33b33d7e38" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "f6b3720b-9e69-41ad-a9b9-5d67249c6fdb"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPageF6b3720b9e6941adA9b95d67249c6fdb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPageF6b3720b9e6941adA9b95d67249c6fdb`}>
      {props["header"] || <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="Header84431bd8Ca574faa8220E413d18365eb" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterA2549d543458454d8d09218cdb868418" {...props} />}
    </div>
  );
}

// id: "dbf15fae-dea6-4586-bd17-98a4c1ecf9d7"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteDbf15faeDea64586Bd1798a4c1ecf9d7(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteDbf15faeDea64586Bd1798a4c1ecf9d7`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "098c3752-23f5-4620-8636-07756ee99fce"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader098c375223f54620863607756ee99fce(props: any) {
  return (
    <ShortHeaderCba15831802f460d999cC5406c94c755 parentTag="MembersAreaHeader098c375223f54620863607756ee99fce" header-top={<DefaultHeaderTop50d011d73e78484098244c5636e7ba62 parentTag="HeaderTop312bcb7f587043d79390E660a625cd90" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "1a8150ee-cf65-4078-b432-5c44b811fa18"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage1a8150eeCf654078B4325c44b811fa18(props: any) {
  return (
    <SecondaryPageF6b3720b9e6941adA9b95d67249c6fdb parentTag="ResourcesPage1a8150eeCf654078B4325c44b811fa18" header={<SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="HeaderB3b75fa3830d43c1Abb9958af99a718e" title="Resources" />} {...props} />
  );
}

// id: "cfe905a2-56ac-4e4f-a7b5-12a77273aed5"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBodyCfe905a256ac4e4fA7b512a77273aed5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBodyCfe905a256ac4e4fA7b512a77273aed5 page-body`}>
      {props["marketingCards"] || <MarketingCards10b70aa389ec43bcA40fDac55c437d40 parentTag="MarketingCards64571aea083344348d14364df160c160" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "9b50b2e1-0829-45a0-9ad2-e21de5f48e66"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label9b50b2e1082945a09ad2E21de5f48e66(props: any) {
  return (
    <DefaultLogoAd39381dEbab4c89B2dbDe2f6a99578e parentTag="Label9b50b2e1082945a09ad2E21de5f48e66" {...props} />
  );
}

// id: "9038e0b5-beb3-4cfd-8ad9-ed124b0db031"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage9038e0b5Beb34cfd8ad9Ed124b0db031(props: any) {
  return (
    <SecondaryPageF6b3720b9e6941adA9b95d67249c6fdb parentTag="JoinUsPage9038e0b5Beb34cfd8ad9Ed124b0db031" header={<SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="HeaderE9530d36Cce143daB4d101eb2200d52f" title="Join Us" />} {...props} />
  );
}

// id: "01aa9cc9-eca2-4433-9c43-257af217c48d"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage01aa9cc9Eca244339c43257af217c48d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage01aa9cc9Eca244339c43257af217c48d page`}>
      {props["header"] || <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="Header1d179a672e9749e4B7163ad1b892bdf3" title="About Us" {...props} />}
      {props["body"] || <MarketingBodyCfe905a256ac4e4fA7b512a77273aed5 parentTag="Body455b5d58E9b2433bBd21D572c2af9895" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterB8e94aaf5d664676997918629916d2ad" {...props} />}
    </div>
  );
}

// id: "deba8597-6bd6-43e8-a753-8db4ba2cffd9"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesDeba85976bd643e8A7538db4ba2cffd9(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesDeba85976bd643e8A7538db4ba2cffd9`}>
      {props["header"] || <MembersAreaHeader098c375223f54620863607756ee99fce parentTag="Header1fb7bdf3130e477dA5742054f47ca77b" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav2c7f87975a2045579aa08c73cbe49f71", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterF66165714c6f43de97780e7df189bcae" {...props} />}
    </div>
  );
}

// id: "28358cab-067d-4c7e-a16a-c0bf206e08c9"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href28358cab067d4c7eA16aC0bf206e08c9 = "/";

// id: "d444b8cc-fdce-4b60-9c50-e88cb6070a78"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterD444b8ccFdce4b609c50E88cb6070a78(props: any) {
  return (
    <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterD444b8ccFdce4b609c50E88cb6070a78" {...props} />
  );
}

// id: "1c52f895-a55f-44b2-a84e-ea8697267d4d"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages1c52f895A55f44b2A84eEa8697267d4d(props: any) {
  return (
    <SecondaryPageF6b3720b9e6941adA9b95d67249c6fdb parentTag="SignupPages1c52f895A55f44b2A84eEa8697267d4d" header={<SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="Header02363437773340beB442Dd81b1b21b80" title="Join Us" />} {...props} />
  );
}

// id: "27518bfd-99a5-46f5-b806-7de5748039bc"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero27518bfd99a546f5B8067de5748039bc(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero27518bfd99a546f5B8067de5748039bc hero`}>
      <div className="title">
        {props["title"] || TitleB34a582dB92940b5B2ec7f66c824a309}
      </div>
    </div>
  );
}

// id: "cba15831-802f-460d-999c-c5406c94c755"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderCba15831802f460d999cC5406c94c755(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderCba15831802f460d999cC5406c94c755 site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo675e9229A2ef4c8d89369bb808cc75e1", parentTag)} label={<DefaultLogoAd39381dEbab4c89B2dbDe2f6a99578e parentTag="Label6c9d245bF1f74b2d943895b0a322b480" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation51682d61B93d424cBe09E1aef338bc26", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "160a6249-fe57-4c40-be5d-7c58c10ebb95"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "cba15831-802f-460d-999c-c5406c94c755"
export const BackgroundImage160a6249Fe574c40Be5d7c58c10ebb95 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "6c93d9e5-7a63-4380-a5a2-56d13f3537d6"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "50d011d7-3e78-4840-9824-4c5636e7ba62"
export function Navigation6c93d9e57a634380A5a256d13f3537d6(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation6c93d9e57a634380A5a256d13f3537d6", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "51682d61-b93d-424c-be09-e1aef338bc26"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "cba15831-802f-460d-999c-c5406c94c755"
export function Navigation51682d61B93d424cBe09E1aef338bc26(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation51682d61B93d424cBe09E1aef338bc26", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "429252ba-522c-423b-a102-2543af45a04b"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "dd7bacbe-371b-4c3b-89b9-5f62abdfa8e5"
export function Links429252ba522c423bA1022543af45a04b(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links429252ba522c423bA1022543af45a04b", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "bf300932-22ee-4277-96ab-d6e131bc8724"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "b367ec71-5bb1-4b95-8f96-fe1422137433"
export function WelcomeBackBf30093222ee427796abD6e131bc8724(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackBf30093222ee427796abD6e131bc8724", parentTag)} {...props} />
  );
}

// id: "8b949990-1aea-4060-b353-88ace6ecdfdb"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "312bcb7f-5870-43d7-9390-e660a625cd90"
export const NavMenuSlug8b9499901aea4060B35388ace6ecdfdb = "members-primary-nav";

// id: "241b07f0-445b-4a63-a8fa-3aa8b273abdd"
// title: ""
// type: :text
// key: "label"
// parent_id: "367546b2-5c57-4321-bc74-6aecc328983c"
export const Label241b07f0445b4a63A8fa3aa8b273abdd = "Join Us";

// id: "28bfef44-234c-4827-ab1f-47f6aea400a2"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader28bfef44234c4827Ab1f47f6aea400a2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader28bfef44234c4827Ab1f47f6aea400a2 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop50d011d73e78484098244c5636e7ba62 parentTag="HeaderTop6be056068b584391B14d5883fdf990c3" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 parentTag="Hero13b22bd440a94857B6f5Cba916270b75" {...props} />}
    </div>
  );
}

// id: "50d011d7-3e78-4840-9824-4c5636e7ba62"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTop50d011d73e78484098244c5636e7ba62(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTop50d011d73e78484098244c5636e7ba62 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo31a72d02D94448c4A626Ddcf2898eb78", parentTag)} label={<DefaultLogoAd39381dEbab4c89B2dbDe2f6a99578e parentTag="Label02ffab36F647431bA130Fe0a2a104115" alt="logo" />} className="linked-logo" url="/" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation6c93d9e57a634380A5a256d13f3537d6", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "13b22bd4-40a9-4857-b6f5-cba916270b75"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "28bfef44-234c-4827-ab1f-47f6aea400a2"
export function Hero13b22bd440a94857B6f5Cba916270b75(props: any) {
  return (
    <HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 parentTag="Hero13b22bd440a94857B6f5Cba916270b75" {...props} />
  );
}

// id: "4641098e-8c6a-4a95-9c24-e9ddc24a5903"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "dd7bacbe-371b-4c3b-89b9-5f62abdfa8e5"
export function Groupflow4641098e8c6a4a959c24E9ddc24a5903(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow4641098e8c6a4a959c24E9ddc24a5903", parentTag)} {...props} />
  );
}

// id: "4f106747-68a0-4c72-979a-1ff4d89a0860"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "10b70aa3-89ec-43bc-a40f-dac55c437d40"
export const ButtonClass4f10674768a04c72979a1ff4d89a0860 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "64571aea-0833-4434-8d14-364df160c160"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "cfe905a2-56ac-4e4f-a7b5-12a77273aed5"
export function MarketingCards64571aea083344348d14364df160c160(props: any) {
  return (
    <MarketingCards10b70aa389ec43bcA40fDac55c437d40 parentTag="MarketingCards64571aea083344348d14364df160c160" postSlug="home-page-posts2" {...props} />
  );
}

// id: "e10b28f2-e332-49ac-9881-e6c16f4018b8"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "28bfef44-234c-4827-ab1f-47f6aea400a2"
export const BackgroundImageE10b28f2E33249ac9881E6c16f4018b8 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/Moving_Forest_1050_700.webp";

// id: "28ca1ed4-fde7-4708-b63d-9ce3d8b697d0"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "5cd530d9-c7d0-468d-bc64-5bec40d44728"
export const ContentSlug28ca1ed4Fde74708B63d9ce3d8b697d0 = "home-page-quote";

// id: "02ffab36-f647-431b-a130-fe0a2a104115"
// title: ""
// type: :reference
// key: "label"
// parent_id: "31a72d02-d944-48c4-a626-ddcf2898eb78"
export function Label02ffab36F647431bA130Fe0a2a104115(props: any) {
  return (
    <DefaultLogoAd39381dEbab4c89B2dbDe2f6a99578e parentTag="Label02ffab36F647431bA130Fe0a2a104115" {...props} />
  );
}

// id: "5acb0631-2dbe-40ad-84b8-10d0fa53ba8f"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header5acb06312dbe40ad84b810d0fa53ba8f(props: any) {
  return (
    <MembersAreaHeader098c375223f54620863607756ee99fce parentTag="Header5acb06312dbe40ad84b810d0fa53ba8f" {...props} />
  );
}

// id: "08b8bb5f-da22-47df-9010-38c2bd6103a2"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePages08b8bb5fDa2247df901038c2bd6103a2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePages08b8bb5fDa2247df901038c2bd6103a2`}>
      {props["header"] || <MembersAreaHeader098c375223f54620863607756ee99fce parentTag="HeaderF5426487F30a4deaB047E96531b3a6b9" {...props} />}
      {props.children}
    </div>
  );
}

// id: "fe7ba3c3-d052-44af-893d-329219ffac7c"
// title: ""
// type: :html
// key: "logo"
// parent_id: "dd7bacbe-371b-4c3b-89b9-5f62abdfa8e5"
export function LogoFe7ba3c3D05244af893d329219ffac7c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} LogoFe7ba3c3D05244af893d329219ffac7c logo`} />
  );
}

// id: "1afac122-96a2-4bfe-ab61-6cb57cae7f14"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "5a95a2ab-6bd4-4f3f-9974-8c3fa44213c6"
export function Hero1afac12296a24bfeAb616cb57cae7f14(props: any) {
  return (
    <SecondaryMarketingHero27518bfd99a546f5B8067de5748039bc parentTag="Hero1afac12296a24bfeAb616cb57cae7f14" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "b8e94aaf-5d66-4676-9979-18629916d2ad"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "01aa9cc9-eca2-4433-9c43-257af217c48d"
export function FooterB8e94aaf5d664676997918629916d2ad(props: any) {
  return (
    <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterB8e94aaf5d664676997918629916d2ad" {...props} />
  );
}

// id: "f5426487-f30a-4dea-b047-e96531b3a6b9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "08b8bb5f-da22-47df-9010-38c2bd6103a2"
export function HeaderF5426487F30a4deaB047E96531b3a6b9(props: any) {
  return (
    <MembersAreaHeader098c375223f54620863607756ee99fce parentTag="HeaderF5426487F30a4deaB047E96531b3a6b9" {...props} />
  );
}

// id: "33056c92-4fb6-4cb8-a8bf-69f6236495ec"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "0ffcd124-5106-448e-aa55-9c33b33d7e38"
export const NavMenuSlug33056c924fb64cb8A8bf69f6236495ec = "marketing-primary-nav";

// id: "3dd8cc6a-a039-4dff-9d3d-1476b67506b4"
// title: ""
// type: :text
// key: "class"
// parent_id: "31a72d02-d944-48c4-a626-ddcf2898eb78"
export const Class3dd8cc6aA0394dff9d3d1476b67506b4 = "linked-logo";

// id: "7509d473-b0c1-4fd5-8aa1-29d864a21739"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "b5bf378c-4b94-4a98-81c1-5208cd8f847c"
export const NavMenuSlug7509d473B0c14fd58aa129d864a21739 = "members-primary-nav";

// id: "5a95a2ab-6bd4-4f3f-9974-8c3fa44213c6"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTop50d011d73e78484098244c5636e7ba62 parentTag="HeaderTopA0833e63C51442e58ee6F95cbb1ebc1c" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero27518bfd99a546f5B8067de5748039bc parentTag="Hero1afac12296a24bfeAb616cb57cae7f14" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "2c7f8797-5a20-4557-9aa0-8c73cbe49f71"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "deba8597-6bd6-43e8-a753-8db4ba2cffd9"
export function SecondaryNav2c7f87975a2045579aa08c73cbe49f71(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav2c7f87975a2045579aa08c73cbe49f71", parentTag)} {...props} />
  );
}

// id: "1d179a67-2e97-49e4-b716-3ad1b892bdf3"
// title: ""
// type: :reference
// key: "header"
// parent_id: "01aa9cc9-eca2-4433-9c43-257af217c48d"
export function Header1d179a672e9749e4B7163ad1b892bdf3(props: any) {
  return (
    <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="Header1d179a672e9749e4B7163ad1b892bdf3" title="About Us" {...props} />
  );
}

// id: "312bcb7f-5870-43d7-9390-e660a625cd90"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "098c3752-23f5-4620-8636-07756ee99fce"
export function HeaderTop312bcb7f587043d79390E660a625cd90(props: any) {
  return (
    <DefaultHeaderTop50d011d73e78484098244c5636e7ba62 parentTag="HeaderTop312bcb7f587043d79390E660a625cd90" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "f6616571-4c6f-43de-9778-0e7df189bcae"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "deba8597-6bd6-43e8-a753-8db4ba2cffd9"
export function FooterF66165714c6f43de97780e7df189bcae(props: any) {
  return (
    <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterF66165714c6f43de97780e7df189bcae" {...props} />
  );
}

// id: "c1c488e0-a043-4df0-acac-a21d8b342e1f"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "e099f4ef-cca1-4127-b324-2bbdea6bf72a"
export function FooterC1c488e0A0434df0AcacA21d8b342e1f(props: any) {
  return (
    <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterC1c488e0A0434df0AcacA21d8b342e1f" {...props} />
  );
}

// id: "3c6b2d69-c6be-40d7-bf5b-6c8c6458d386"
// title: ""
// type: :text
// key: "title"
// parent_id: "e9530d36-cce1-43da-b4d1-01eb2200d52f"
export const Title3c6b2d69C6be40d7Bf5b6c8c6458d386 = "Join Us";

// id: "6c9d245b-f1f7-4b2d-9438-95b0a322b480"
// title: ""
// type: :reference
// key: "label"
// parent_id: "675e9229-a2ef-4c8d-8936-9bb808cc75e1"
export function Label6c9d245bF1f74b2d943895b0a322b480(props: any) {
  return (
    <DefaultLogoAd39381dEbab4c89B2dbDe2f6a99578e parentTag="Label6c9d245bF1f74b2d943895b0a322b480" {...props} />
  );
}

// id: "11cdd704-bc22-4598-899a-5dd3eb7224a0"
// title: ""
// type: :text
// key: "href"
// parent_id: "367546b2-5c57-4321-bc74-6aecc328983c"
export const Href11cdd704Bc224598899a5dd3eb7224a0 = "/join";

// id: "b367ec71-5bb1-4b95-8f96-fe1422137433"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageB367ec715bb14b958f96Fe1422137433(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageB367ec715bb14b958f96Fe1422137433 page`}>
      {props["header"] || <HomeHeader28bfef44234c4827Ab1f47f6aea400a2 parentTag="Header779a09adDf624a438796Ad22bba401a2" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBackBf30093222ee427796abD6e131bc8724", parentTag)} {...props} />}
      {props["body"] || <MarketingBodyCfe905a256ac4e4fA7b512a77273aed5 parentTag="BodyB97ed0a49cae4973Bc4a464c193a9dea" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote5cd530d9C7d0468dBc645bec40d44728", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="Footer5c5945391e8642a6AaeaA3f49309e14b" {...props} />}
    </div>
  );
}

// id: "455b5d58-e9b2-433b-bd21-d572c2af9895"
// title: ""
// type: :reference
// key: "body"
// parent_id: "01aa9cc9-eca2-4433-9c43-257af217c48d"
export function Body455b5d58E9b2433bBd21D572c2af9895(props: any) {
  return (
    <MarketingBodyCfe905a256ac4e4fA7b512a77273aed5 parentTag="Body455b5d58E9b2433bBd21D572c2af9895" postSlug="about-us-posts" {...props} />
  );
}

// id: "8f5be371-5fff-495e-ac0e-3e6c6414db68"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "dd7bacbe-371b-4c3b-89b9-5f62abdfa8e5"
export function Copyright8f5be3715fff495eAc0e3e6c6414db68(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright8f5be3715fff495eAc0e3e6c6414db68", parentTag)} {...props} />
  );
}

// id: "367546b2-5c57-4321-bc74-6aecc328983c"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "fe13e180-ec0a-4a22-a8e4-09a23a6ff471"
export function Button367546b25c574321Bc746aecc328983c(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button367546b25c574321Bc746aecc328983c", parentTag)} label="Join Us" href="/join" {...props} />
  );
}

// id: "a0833e63-c514-42e5-8ee6-f95cbb1ebc1c"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "5a95a2ab-6bd4-4f3f-9974-8c3fa44213c6"
export function HeaderTopA0833e63C51442e58ee6F95cbb1ebc1c(props: any) {
  return (
    <DefaultHeaderTop50d011d73e78484098244c5636e7ba62 parentTag="HeaderTopA0833e63C51442e58ee6F95cbb1ebc1c" {...props} />
  );
}

// id: "ab29611c-f731-4749-89fb-cd1e8b725c06"
// title: ""
// type: :text
// key: "url"
// parent_id: "31a72d02-d944-48c4-a626-ddcf2898eb78"
export const UrlAb29611cF731474989fbCd1e8b725c06 = "/";

// id: "e5d233cb-6cf0-46f3-aa0b-dcde1209e23b"
// title: ""
// type: :text
// key: "title"
// parent_id: "84431bd8-ca57-4faa-8220-e413d18365eb"
export const TitleE5d233cb6cf046f3Aa0bDcde1209e23b = "Secondary Page";

// id: "2b567b14-7f18-44d4-b6a3-629394418d68"
// title: ""
// type: :text
// key: "title"
// parent_id: "02363437-7733-40be-b442-dd81b1b21b80"
export const Title2b567b147f1844d4B6a3629394418d68 = "Join Us";

// id: "10b70aa3-89ec-43bc-a40f-dac55c437d40"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards10b70aa389ec43bcA40fDac55c437d40(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards10b70aa389ec43bcA40fDac55c437d40", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" postSlug="home-page-posts2" {...props} />
  );
}

// id: "e9530d36-cce1-43da-b4d1-01eb2200d52f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9038e0b5-beb3-4cfd-8ad9-ed124b0db031"
export function HeaderE9530d36Cce143daB4d101eb2200d52f(props: any) {
  return (
    <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="HeaderE9530d36Cce143daB4d101eb2200d52f" title="Join Us" {...props} />
  );
}

// id: "b5bf378c-4b94-4a98-81c1-5208cd8f847c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "e099f4ef-cca1-4127-b324-2bbdea6bf72a"
export function HeaderB5bf378c4b944a9881c15208cd8f847c(props: any) {
  return (
    <MembersAreaHeader098c375223f54620863607756ee99fce parentTag="HeaderB5bf378c4b944a9881c15208cd8f847c" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "5c594539-1e86-42a6-aaea-a3f49309e14b"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b367ec71-5bb1-4b95-8f96-fe1422137433"
export function Footer5c5945391e8642a6AaeaA3f49309e14b(props: any) {
  return (
    <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="Footer5c5945391e8642a6AaeaA3f49309e14b" {...props} />
  );
}

// id: "31a72d02-d944-48c4-a626-ddcf2898eb78"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "50d011d7-3e78-4840-9824-4c5636e7ba62"
export function LinkedLogo31a72d02D94448c4A626Ddcf2898eb78(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo31a72d02D94448c4A626Ddcf2898eb78", parentTag)} label={<DefaultLogoAd39381dEbab4c89B2dbDe2f6a99578e parentTag="Label02ffab36F647431bA130Fe0a2a104115" alt="logo" />} className="linked-logo" url="/" {...props} />
  );
}

// id: "5cd530d9-c7d0-468d-bc64-5bec40d44728"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "b367ec71-5bb1-4b95-8f96-fe1422137433"
export function Quote5cd530d9C7d0468dBc645bec40d44728(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote5cd530d9C7d0468dBc645bec40d44728", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "2c4bcc72-ee28-45fb-80f1-1d1c0c3c4b0d"
// title: ""
// type: :text
// key: "title"
// parent_id: "1afac122-96a2-4bfe-ab61-6cb57cae7f14"
export const Title2c4bcc72Ee2845fb80f11d1c0c3c4b0d = "Secondary Marketing Title 2";

// id: "35bcd614-ddfa-4e1e-b636-453a6047d25a"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "6c93d9e5-7a63-4380-a5a2-56d13f3537d6"
export const NavMenuSlug35bcd614Ddfa4e1eB636453a6047d25a = "marketing-primary-nav";

// id: "2536f169-2380-4245-a4d8-64a540ee5f87"
// title: ""
// type: :text
// key: "title"
// parent_id: "16ad451a-b2df-4211-b5ab-e9d5a4574e8e"
export const Title2536f16923804245A4d864a540ee5f87 = "Leveling";

// id: "6a2bb37e-2c7c-43fd-8af7-eb44c7fe913f"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPage6a2bb37e2c7c43fd8af7Eb44c7fe913f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPage6a2bb37e2c7c43fd8af7Eb44c7fe913f`}>
      {props["header"] || <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="HeaderF1c65d322a8643cf8def1a23b0f3d07c" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="Footer02c18bbfFd0749c5916177f11abfe654" {...props} />}
    </div>
  );
}

// id: "675e9229-a2ef-4c8d-8936-9bb808cc75e1"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "cba15831-802f-460d-999c-c5406c94c755"
export function LinkedLogo675e9229A2ef4c8d89369bb808cc75e1(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo675e9229A2ef4c8d89369bb808cc75e1", parentTag)} label={<DefaultLogoAd39381dEbab4c89B2dbDe2f6a99578e parentTag="Label6c9d245bF1f74b2d943895b0a322b480" alt="logo" />} {...props} />
  );
}

// id: "f1c65d32-2a86-43cf-8def-1a23b0f3d07c"
// title: ""
// type: :reference
// key: "header"
// parent_id: "6a2bb37e-2c7c-43fd-8af7-eb44c7fe913f"
export function HeaderF1c65d322a8643cf8def1a23b0f3d07c(props: any) {
  return (
    <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="HeaderF1c65d322a8643cf8def1a23b0f3d07c" title="Public Events" {...props} />
  );
}

// id: "02c18bbf-fd07-49c5-9161-77f11abfe654"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "6a2bb37e-2c7c-43fd-8af7-eb44c7fe913f"
export function Footer02c18bbfFd0749c5916177f11abfe654(props: any) {
  return (
    <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="Footer02c18bbfFd0749c5916177f11abfe654" {...props} />
  );
}

// id: "6be05606-8b58-4391-b14d-5883fdf990c3"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "28bfef44-234c-4827-ab1f-47f6aea400a2"
export function HeaderTop6be056068b584391B14d5883fdf990c3(props: any) {
  return (
    <DefaultHeaderTop50d011d73e78484098244c5636e7ba62 parentTag="HeaderTop6be056068b584391B14d5883fdf990c3" {...props} />
  );
}

// id: "1fb7bdf3-130e-477d-a574-2054f47ca77b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "deba8597-6bd6-43e8-a753-8db4ba2cffd9"
export function Header1fb7bdf3130e477dA5742054f47ca77b(props: any) {
  return (
    <MembersAreaHeader098c375223f54620863607756ee99fce parentTag="Header1fb7bdf3130e477dA5742054f47ca77b" {...props} />
  );
}

// id: "13758849-5248-4a06-a6d1-616199801f8e"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "455b5d58-e9b2-433b-bd21-d572c2af9895"
export const PostSlug1375884952484a06A6d1616199801f8e = "about-us-posts";

// id: "bde02254-4860-4f40-9e2a-287ede313a6c"
// title: ""
// type: :text
// key: "title"
// parent_id: "f1c65d32-2a86-43cf-8def-1a23b0f3d07c"
export const TitleBde0225448604f409e2a287ede313a6c = "Public Events";

// id: "dd7bacbe-371b-4c3b-89b9-5f62abdfa8e5"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links429252ba522c423bA1022543af45a04b", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <LogoFe7ba3c3D05244af893d329219ffac7c />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright8f5be3715fff495eAc0e3e6c6414db68", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow4641098e8c6a4a959c24E9ddc24a5903", parentTag)} {...props} />}
    </div>
  );
}

// id: "84431bd8-ca57-4faa-8220-e413d18365eb"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f6b3720b-9e69-41ad-a9b9-5d67249c6fdb"
export function Header84431bd8Ca574faa8220E413d18365eb(props: any) {
  return (
    <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="Header84431bd8Ca574faa8220E413d18365eb" title="Secondary Page" {...props} />
  );
}

// id: "b97ed0a4-9cae-4973-bc4a-464c193a9dea"
// title: ""
// type: :reference
// key: "body"
// parent_id: "b367ec71-5bb1-4b95-8f96-fe1422137433"
export function BodyB97ed0a49cae4973Bc4a464c193a9dea(props: any) {
  return (
    <MarketingBodyCfe905a256ac4e4fA7b512a77273aed5 parentTag="BodyB97ed0a49cae4973Bc4a464c193a9dea" {...props} />
  );
}

// id: "0a4999fe-d40a-4797-b0e1-d2cb61fafc39"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "ad39381d-ebab-4c89-b2db-de2f6a99578e"
export const ImageUrl0a4999feD40a4797B0e1D2cb61fafc39 = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "02363437-7733-40be-b442-dd81b1b21b80"
// title: ""
// type: :reference
// key: "header"
// parent_id: "1c52f895-a55f-44b2-a84e-ea8697267d4d"
export function Header02363437773340beB442Dd81b1b21b80(props: any) {
  return (
    <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="Header02363437773340beB442Dd81b1b21b80" title="Join Us" {...props} />
  );
}

// id: "a2549d54-3458-454d-8d09-218cdb868418"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f6b3720b-9e69-41ad-a9b9-5d67249c6fdb"
export function FooterA2549d543458454d8d09218cdb868418(props: any) {
  return (
    <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterA2549d543458454d8d09218cdb868418" {...props} />
  );
}

// id: "d4f3e23b-5421-449e-a5e8-c188c3283567"
// title: ""
// type: :text
// key: "class"
// parent_id: "6c93d9e5-7a63-4380-a5a2-56d13f3537d6"
export const ClassD4f3e23b5421449eA5e8C188c3283567 = "navigation";

// id: "4ddeb5a6-c873-4e27-b037-45fe0f59773f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "429252ba-522c-423b-a102-2543af45a04b"
export const NavMenuSlug4ddeb5a6C8734e27B03745fe0f59773f = "footer-nav";

// id: "c2dd4e3c-32fd-42ed-8950-583da5204c42"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageC2dd4e3c32fd42ed8950583da5204c42(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageC2dd4e3c32fd42ed8950583da5204c42`}>
      {props["header"] || <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="Header16ad451aB2df4211B5abE9d5a4574e8e" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterFda3fc457874473e94f5Aaa0ff59b89b" {...props} />}
    </div>
  );
}

// id: "b34a582d-b929-40b5-b2ec-7f66c824a309"
// title: ""
// type: :text
// key: "title"
// parent_id: "27518bfd-99a5-46f5-b806-7de5748039bc"
export const TitleB34a582dB92940b5B2ec7f66c824a309 = "Default Title";

// id: "1e298121-8714-4393-9014-afce436e1671"
// title: ""
// type: :text
// key: "alt"
// parent_id: "ad39381d-ebab-4c89-b2db-de2f6a99578e"
export const Alt1e298121871443939014Afce436e1671 = "logo";

// id: "779a09ad-df62-4a43-8796-ad22bba401a2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b367ec71-5bb1-4b95-8f96-fe1422137433"
export function Header779a09adDf624a438796Ad22bba401a2(props: any) {
  return (
    <HomeHeader28bfef44234c4827Ab1f47f6aea400a2 parentTag="Header779a09adDf624a438796Ad22bba401a2" {...props} />
  );
}

// id: "e0f8093a-21c4-4f5b-b332-199da19a04c9"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "10b70aa3-89ec-43bc-a40f-dac55c437d40"
export const PostSlugE0f8093a21c44f5bB332199da19a04c9 = "home-page-posts2";

// id: "16ad451a-b2df-4211-b5ab-e9d5a4574e8e"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c2dd4e3c-32fd-42ed-8950-583da5204c42"
export function Header16ad451aB2df4211B5abE9d5a4574e8e(props: any) {
  return (
    <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="Header16ad451aB2df4211B5abE9d5a4574e8e" title="Leveling" {...props} />
  );
}

// id: "c728270d-1a29-4a1f-bbbe-81e912bb2999"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "51682d61-b93d-424c-be09-e1aef338bc26"
export const NavMenuSlugC728270d1a294a1fBbbe81e912bb2999 = "members-primary-nav";

// id: "f02aa27c-e584-4893-a426-b66a1959029d"
// title: ""
// type: :text
// key: "title"
// parent_id: "1d179a67-2e97-49e4-b716-3ad1b892bdf3"
export const TitleF02aa27cE5844893A426B66a1959029d = "About Us";

// id: "b8664810-1d42-4deb-baf2-da5cdb006452"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleB86648101d424debBaf2Da5cdb006452 = "Leveling";

// id: "365f0742-202d-4142-bad1-3de0af18f5b6"
// title: ""
// type: :text
// key: "title"
// parent_id: "5a95a2ab-6bd4-4f3f-9974-8c3fa44213c6"
export const Title365f0742202d4142Bad13de0af18f5b6 = "Secondary Marketing Title 1";

// id: "b12b3ded-7a18-4103-9073-c7b3c223d04b"
// title: ""
// type: :text
// key: "title"
// parent_id: "0ffcd124-5106-448e-aa55-9c33b33d7e38"
export const TitleB12b3ded7a1841039073C7b3c223d04b = null;

// id: "b3b75fa3-830d-43c1-abb9-958af99a718e"
// title: ""
// type: :reference
// key: "header"
// parent_id: "1a8150ee-cf65-4078-b432-5c44b811fa18"
export function HeaderB3b75fa3830d43c1Abb9958af99a718e(props: any) {
  return (
    <SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6 parentTag="HeaderB3b75fa3830d43c1Abb9958af99a718e" title="Resources" {...props} />
  );
}

// id: "fda3fc45-7874-473e-94f5-aaa0ff59b89b"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "c2dd4e3c-32fd-42ed-8950-583da5204c42"
export function FooterFda3fc457874473e94f5Aaa0ff59b89b(props: any) {
  return (
    <DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5 parentTag="FooterFda3fc457874473e94f5Aaa0ff59b89b" {...props} />
  );
}

// id: "a69bd70a-6264-4476-8b7d-59159f3db565"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "64571aea-0833-4434-8d14-364df160c160"
export const PostSlugA69bd70a626444768b7d59159f3db565 = "home-page-posts2";

// id: "012fe78c-231b-4253-aecf-5aa81b457cff"
// title: ""
// type: :text
// key: "title"
// parent_id: "b3b75fa3-830d-43c1-abb9-958af99a718e"
export const Title012fe78c231b4253Aecf5aa81b457cff = "Resources";

// id: "fe13e180-ec0a-4a22-a8e4-09a23a6ff471"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471 hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button367546b25c574321Bc746aecc328983c", parentTag)} label="Join Us" href="/join" {...props} />}
      </div>
    </div>
  );
}

const Components = {
  AdminPagesD664fc857f4b4bdaA6262c92d8cd51cd,
  MembersAreaDefaultE099f4efCca14127B3242bbdea6bf72a,
  DefaultLogoAd39381dEbab4c89B2dbDe2f6a99578e,
  DefaultHeader0ffcd1245106448eAa559c33b33d7e38,
  SecondaryPageF6b3720b9e6941adA9b95d67249c6fdb,
  PageQuoteDbf15faeDea64586Bd1798a4c1ecf9d7,
  MembersAreaHeader098c375223f54620863607756ee99fce,
  ResourcesPage1a8150eeCf654078B4325c44b811fa18,
  MarketingBodyCfe905a256ac4e4fA7b512a77273aed5,
  Label9b50b2e1082945a09ad2E21de5f48e66,
  JoinUsPage9038e0b5Beb34cfd8ad9Ed124b0db031,
  AboutUsPage01aa9cc9Eca244339c43257af217c48d,
  MemberPagesDeba85976bd643e8A7538db4ba2cffd9,
  Href28358cab067d4c7eA16aC0bf206e08c9,
  FooterD444b8ccFdce4b609c50E88cb6070a78,
  SignupPages1c52f895A55f44b2A84eEa8697267d4d,
  SecondaryMarketingHero27518bfd99a546f5B8067de5748039bc,
  ShortHeaderCba15831802f460d999cC5406c94c755,
  BackgroundImage160a6249Fe574c40Be5d7c58c10ebb95,
  Navigation6c93d9e57a634380A5a256d13f3537d6,
  Navigation51682d61B93d424cBe09E1aef338bc26,
  Links429252ba522c423bA1022543af45a04b,
  WelcomeBackBf30093222ee427796abD6e131bc8724,
  NavMenuSlug8b9499901aea4060B35388ace6ecdfdb,
  Label241b07f0445b4a63A8fa3aa8b273abdd,
  HomeHeader28bfef44234c4827Ab1f47f6aea400a2,
  DefaultHeaderTop50d011d73e78484098244c5636e7ba62,
  Hero13b22bd440a94857B6f5Cba916270b75,
  Groupflow4641098e8c6a4a959c24E9ddc24a5903,
  ButtonClass4f10674768a04c72979a1ff4d89a0860,
  MarketingCards64571aea083344348d14364df160c160,
  BackgroundImageE10b28f2E33249ac9881E6c16f4018b8,
  ContentSlug28ca1ed4Fde74708B63d9ce3d8b697d0,
  Label02ffab36F647431bA130Fe0a2a104115,
  Header5acb06312dbe40ad84b810d0fa53ba8f,
  MessagePages08b8bb5fDa2247df901038c2bd6103a2,
  LogoFe7ba3c3D05244af893d329219ffac7c,
  Hero1afac12296a24bfeAb616cb57cae7f14,
  FooterB8e94aaf5d664676997918629916d2ad,
  HeaderF5426487F30a4deaB047E96531b3a6b9,
  NavMenuSlug33056c924fb64cb8A8bf69f6236495ec,
  Class3dd8cc6aA0394dff9d3d1476b67506b4,
  NavMenuSlug7509d473B0c14fd58aa129d864a21739,
  SecondaryMarketingHeader5a95a2ab6bd44f3f99748c3fa44213c6,
  SecondaryNav2c7f87975a2045579aa08c73cbe49f71,
  Header1d179a672e9749e4B7163ad1b892bdf3,
  HeaderTop312bcb7f587043d79390E660a625cd90,
  FooterF66165714c6f43de97780e7df189bcae,
  FooterC1c488e0A0434df0AcacA21d8b342e1f,
  Title3c6b2d69C6be40d7Bf5b6c8c6458d386,
  Label6c9d245bF1f74b2d943895b0a322b480,
  Href11cdd704Bc224598899a5dd3eb7224a0,
  HomePageB367ec715bb14b958f96Fe1422137433,
  Body455b5d58E9b2433bBd21D572c2af9895,
  Copyright8f5be3715fff495eAc0e3e6c6414db68,
  Button367546b25c574321Bc746aecc328983c,
  HeaderTopA0833e63C51442e58ee6F95cbb1ebc1c,
  UrlAb29611cF731474989fbCd1e8b725c06,
  TitleE5d233cb6cf046f3Aa0bDcde1209e23b,
  Title2b567b147f1844d4B6a3629394418d68,
  MarketingCards10b70aa389ec43bcA40fDac55c437d40,
  HeaderE9530d36Cce143daB4d101eb2200d52f,
  HeaderB5bf378c4b944a9881c15208cd8f847c,
  Footer5c5945391e8642a6AaeaA3f49309e14b,
  LinkedLogo31a72d02D94448c4A626Ddcf2898eb78,
  Quote5cd530d9C7d0468dBc645bec40d44728,
  Title2c4bcc72Ee2845fb80f11d1c0c3c4b0d,
  NavMenuSlug35bcd614Ddfa4e1eB636453a6047d25a,
  Title2536f16923804245A4d864a540ee5f87,
  PublicEventsPage6a2bb37e2c7c43fd8af7Eb44c7fe913f,
  LinkedLogo675e9229A2ef4c8d89369bb808cc75e1,
  HeaderF1c65d322a8643cf8def1a23b0f3d07c,
  Footer02c18bbfFd0749c5916177f11abfe654,
  HeaderTop6be056068b584391B14d5883fdf990c3,
  Header1fb7bdf3130e477dA5742054f47ca77b,
  PostSlug1375884952484a06A6d1616199801f8e,
  TitleBde0225448604f409e2a287ede313a6c,
  DefaultFooterDd7bacbe371b4c3b89b95f62abdfa8e5,
  Header84431bd8Ca574faa8220E413d18365eb,
  BodyB97ed0a49cae4973Bc4a464c193a9dea,
  ImageUrl0a4999feD40a4797B0e1D2cb61fafc39,
  Header02363437773340beB442Dd81b1b21b80,
  FooterA2549d543458454d8d09218cdb868418,
  ClassD4f3e23b5421449eA5e8C188c3283567,
  NavMenuSlug4ddeb5a6C8734e27B03745fe0f59773f,
  AboutLevelingPageC2dd4e3c32fd42ed8950583da5204c42,
  TitleB34a582dB92940b5B2ec7f66c824a309,
  Alt1e298121871443939014Afce436e1671,
  Header779a09adDf624a438796Ad22bba401a2,
  PostSlugE0f8093a21c44f5bB332199da19a04c9,
  Header16ad451aB2df4211B5abE9d5a4574e8e,
  NavMenuSlugC728270d1a294a1fBbbe81e912bb2999,
  TitleF02aa27cE5844893A426B66a1959029d,
  TitleB86648101d424debBaf2Da5cdb006452,
  Title365f0742202d4142Bad13de0af18f5b6,
  TitleB12b3ded7a1841039073C7b3c223d04b,
  HeaderB3b75fa3830d43c1Abb9958af99a718e,
  FooterFda3fc457874473e94f5Aaa0ff59b89b,
  PostSlugA69bd70a626444768b7d59159f3db565,
  Title012fe78c231b4253Aecf5aa81b457cff,
  HomePageHeroFe13e180Ec0a4a22A8e409a23a6ff471
}

export default Components;