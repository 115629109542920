const ROUTES = {
  "/": {
    "name": "HomePageB367ec715bb14b958f96Fe1422137433",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesD664fc857f4b4bdaA6262c92d8cd51cd",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage01aa9cc9Eca244339c43257af217c48d",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageC2dd4e3c32fd42ed8950583da5204c42",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/events/public": {
    "name": "PublicEventsPage6a2bb37e2c7c43fd8af7Eb44c7fe913f",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage9038e0b5Beb34cfd8ad9Ed124b0db031",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPagesDeba85976bd643e8A7538db4ba2cffd9",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePages08b8bb5fDa2247df901038c2bd6103a2",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage1a8150eeCf654078B4325c44b811fa18",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages1c52f895A55f44b2A84eEa8697267d4d",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;